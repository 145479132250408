import {
  Button,
  Card,
  Dialog,
  DialogContent,
  Grid,
  Icon,
  makeStyles,
  TextField,
  Typography,
} from "@material-ui/core"
import React, { useState } from "react"
import Layout from "../components/Layout"
import { useForm, ValidationError } from "@formspree/react"
import customTheme from "../gatsby-theme-material-ui-top-layout/customTheme"
import {
  ArrowDownward,
  LocationOn,
  Mail,
  PhoneAndroid,
  CallEnd,
} from "@material-ui/icons"

const useStyles = makeStyles(theme => ({
  sectionSeperator: {
    marginBottom: "8rem",
  },
  pageTitle: {
    fontSize: "3.2rem",
    fontWeight: "bold",
    textAlign: "center",
    marginBottom: "4rem",
    [customTheme.breakpoints.down("sm")]: {
      fontSize: "2.4rem",
    },
    [customTheme.breakpoints.down("xs")]: {
      fontSize: "1.6rem",
    },
  },
  mapContainer: {
    transition: "all .2s",
    transform: "translateY(20%)",
    "&:hover": {
      transform: "translateY(0%)",
    },
  },
  mapIframe: {
    width: "100%",
    height: "40rem",
    border: "none",
  },
  contactUsContent: {
    zIndex: "10",
    width: "100%",
    display: "flex",
    justifyContent: "space-evenly",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
    },
  },
  form: {
    padding: "2rem",
    textAlign: "center",
    backgroundColor: "transparent",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  formGroup: {
    display: "flex",
    flexDirection: "column",
    textAlign: "right",
    marginBottom: "1rem",
    padding: "1rem",
  },
  label: {
    fontSize: "1.2rem",
    fontFamily: "inherit",
    marginRight: ".5rem",
    fontWeight: "bold",
    [theme.breakpoints.down("xs")]: {
      fontSize: "1rem",
    },
  },
  inputText: {
    fontSize: "1.6rem",
    width: "40rem",
    [theme.breakpoints.down("sm")]: {
      fontSize: "1.2rem",
      width: "28rem",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "1rem",
      width: "28rem",
    },
  },
  textArea: {
    fontSize: "1.8rem",
    width: "40rem",
    [theme.breakpoints.down("xs")]: {
      width: "28rem",
    },
  },
  button: {
    fontSize: "2rem",
    margin: "4rem auto 0",
    width: "40rem",
    [theme.breakpoints.down("sm")]: {
      fontSize: "1.2rem",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "1rem",
      width: "28rem",
    },
    "&:hover": {
      color: "#fff",
    },
  },
  buttonBack: {
    marginTop: "1rem",
    "&:hover": {
      color: customTheme.palette.primary.main,
    },
  },
  thanksText: {
    color: customTheme.palette.primary.main,
    fontSize: "2.4rem",
    fontWeight: "bold",
    textAlign: "center",
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    [customTheme.breakpoints.down("sm")]: {
      fontSize: "1.8rem",
    },
    [customTheme.breakpoints.down("xs")]: {
      fontSize: "1.6rem",
    },
  },
  details: {
    textAlign: "center",
    height: "100%",
    padding: "2rem",
    color: "#fff",
    backgroundColor: customTheme.palette.primary.main,
  },
  detailsTitle: {
    color: "#fff",
    fontSize: "2.4rem",
    fontWeight: "bold",
    textAlign: "center",
    [customTheme.breakpoints.down("sm")]: {
      fontSize: "1.8rem",
    },
    [customTheme.breakpoints.down("xs")]: {
      fontSize: "1.6rem",
    },
  },
  detailsInfo: {
    color: "#fff",
    fontSize: "1.6rem",
    textDecoration: "none",
  },
  detailsInfoClickable: {
    "&:hover": {
      color: "#fff",
    },
  },
  redText: {
    color: customTheme.palette.primary.main,
    fontSize: "2rem",
    transition: "all .2s",
    "&:hover": {
      textDecoration: "none",
      color: customTheme.palette.primary.main,
      fontSize: "2.5rem",
      cursor: "pointer",
    },
    [customTheme.breakpoints.down("xs")]: {
      fontSize: "1.6rem",
    },
  },
}))

// The Form
function ContactForm(props) {
  const {
    classes,
    isDialogOpen,
    setIsDialogOpen,
    onChange,
    name,
    phone,
    email,
    message,
    isValidName,
    isValidEmail,
    isValidPhone,
    isValidMessage,
  } = props

  const [state, handleSubmit] = useForm("xjvjkgzg")

  if (state.succeeded) {
    return (
      <p className={classes.thanksText}>
        شكرا لتواصلك معنا
        <br />
        سيصلكم الرد في أقرب وقت حال مراجعة الرسالة
      </p>
    )
  }
  return (
    <Dialog open={isDialogOpen} onClose={() => setIsDialogOpen(false)}>
      <DialogContent>
        <Typography
          variant="h2"
          className={classes.detailsTitle}
          style={{ color: "#000" }}
        >
          الرجاء التأكيد على بياناتك
          <br />
          قبل الإرسال
        </Typography>
        <form onSubmit={handleSubmit} className={classes.form} id="form">
          {/* Name */}
          <div className={classes.formGroup}>
            <label htmlFor="name" className={classes.label}>
              الاسم
            </label>
            <TextField
              required
              disabled
              id="name"
              type="text"
              name="Name"
              placeholder="الاسم الكامل"
              label="Name"
              className={classes.inputText}
              helperText="يمكن استخدام الأحرف العربية والانجليزية"
              onChange={onChange}
              value={name}
              InputProps={{
                classes: {
                  input: classes.inputText,
                },
              }}
            />
            <ValidationError prefix="Name" field="name" errors={state.errors} />
          </div>
          {/* Phone */}
          <div className={classes.formGroup}>
            <label htmlFor="phone" className={classes.label}>
              رقم الهاتف
            </label>
            <TextField
              required
              disabled
              id="phone"
              type="tel"
              name="Phone Number"
              placeholder="رقم الهاتف"
              label="Phone number"
              className={classes.inputText}
              helperText="يجب أن يتكون رقم الهاتف من عشرة أرقام"
              onChange={onChange}
              value={phone}
              InputProps={{
                classes: {
                  input: classes.inputText,
                },
              }}
            />
            <ValidationError
              prefix="Phone"
              field="phone"
              errors={state.errors}
            />
          </div>
          {/* Email */}
          <div className={classes.formGroup}>
            <label htmlFor="email" className={classes.label}>
              البريد الإلكتروني
            </label>
            <TextField
              required
              disabled
              id="email"
              type="email"
              name="Email Address"
              placeholder="example@example.com"
              label="Email"
              className={classes.inputText}
              onChange={onChange}
              value={email}
              InputProps={{
                classes: {
                  input: classes.inputText,
                },
              }}
            />
            <ValidationError
              prefix="Email"
              field="email"
              errors={state.errors}
            />
          </div>
          {/* message */}
          <div className={classes.formGroup}>
            <label htmlFor="message" className={classes.label}>
              كيف يمكننا العمل معا؟
            </label>
            <TextField
              required
              disabled
              multiline
              id="message"
              name="Message"
              rows={10}
              placeholder="رسالتك لنا"
              label="Your message"
              className={classes.textArea}
              helperText="يمكن استخدام الأحرف العربية والانجليزية والأرقام والرموز @#$&%=,_"
              onChange={onChange}
              value={message}
              InputProps={{
                classes: {
                  input: classes.inputText,
                },
              }}
            />
            <ValidationError
              prefix="Message"
              field="message"
              errors={state.errors}
            />
          </div>
          {/* Buttons */}
          <div className={classes.formGroup}>
            <Button
              type="submit"
              color="primary"
              variant="contained"
              className={classes.button}
              fullWidth
              disabled={
                state.submitting ||
                !isValidName ||
                !isValidPhone ||
                !isValidEmail ||
                !isValidMessage
              }
            >
              تأكيد
            </Button>
            <Button
              onClick={() => setIsDialogOpen(false)}
              color="primary"
              variant="outlined"
              className={`${classes.button} ${classes.buttonBack}`}
              fullWidth
            >
              رجوع
            </Button>
          </div>
        </form>
      </DialogContent>
    </Dialog>
  )
}

// Initial form to prevent unauthorized button submitting
const DecoyForm = props => {
  const {
    classes,
    onChange,
    name,
    phone,
    email,
    message,
    isValidName,
    isValidEmail,
    isValidPhone,
    isValidMessage,
    validate,
  } = props
  return (
    <form onSubmit={validate} className={classes.form} id="form">
      {/* Name */}
      <div className={classes.formGroup}>
        <label htmlFor="name" className={classes.label}>
          الاسم
        </label>
        <TextField
          required
          id="name"
          type="text"
          name="name"
          placeholder="الاسم الكامل"
          label="Name"
          className={classes.inputText}
          helperText="يمكن استخدام الأحرف العربية والانجليزية"
          onChange={event => {
            onChange(event)
          }}
          value={name}
          InputProps={{
            classes: {
              input: classes.inputText,
            },
          }}
        />
      </div>
      {/* Phone */}
      <div className={classes.formGroup}>
        <label htmlFor="phone" className={classes.label}>
          رقم الهاتف
        </label>
        <TextField
          required
          id="phone"
          type="tel"
          name="phone"
          placeholder="رقم الهاتف"
          label="Phone number"
          className={classes.inputText}
          helperText="يجب أن يتكون رقم الهاتف من عشرة أرقام"
          onChange={event => {
            onChange(event)
          }}
          value={phone}
          InputProps={{
            classes: {
              input: classes.inputText,
            },
          }}
        />
      </div>
      {/* Email */}
      <div className={classes.formGroup}>
        <label htmlFor="email" className={classes.label}>
          البريد الإلكتروني
        </label>
        <TextField
          required
          id="email"
          type="email"
          name="email"
          placeholder="example@example.com"
          label="Email"
          className={classes.inputText}
          onChange={event => {
            onChange(event)
          }}
          value={email}
          InputProps={{
            classes: {
              input: classes.inputText,
            },
          }}
        />
      </div>
      {/* message */}
      <div className={classes.formGroup}>
        <label htmlFor="message" className={classes.label}>
          كيف يمكننا العمل معا؟
        </label>
        <TextField
          required
          multiline
          id="message"
          name="message"
          rows={10}
          placeholder="رسالتك لنا"
          label="Your message"
          className={classes.textArea}
          helperText="يمكن استخدام الأحرف العربية والانجليزية والأرقام والرموز @#$&%=,_"
          onChange={event => {
            onChange(event)
          }}
          value={message}
          InputProps={{
            classes: {
              input: classes.inputText,
            },
          }}
        />
      </div>
      <Button
        onClick={validate}
        color="primary"
        variant="contained"
        className={classes.button}
        fullWidth
        disabled={
          !isValidName || !isValidPhone || !isValidEmail || !isValidMessage
        }
      >
        إرسال
      </Button>
    </form>
  )
}

const Contact = () => {
  const pageMeta = {
    title: "تواصل معنا",
    description: "للتواصل مع شركة التميز للاستشارات وأمن المعلومات",
    keywords: [
      "تواصل",
      "حماية",
      "خدمات",
      "شركة",
      "التميز",
      "احتراف",
      "آيزو",
      "iso",
      "pcidss",
      "ليبيا",
      "Libya",
    ],
  }
  const classes = useStyles()
  const [isDialogOpen, setIsDialogOpen] = useState(false)

  const [name, setName] = useState("")
  const [phone, setPhone] = useState("")
  const [email, setEmail] = useState("")
  const [message, setMessage] = useState("")
  const [isValidName, setIsValidName] = useState(false)
  const [isValidEmail, setIsValidEmail] = useState(false)
  const [isValidPhone, setIsValidPhone] = useState(false)
  const [isValidMessage, setIsValidMessage] = useState(false)

  const testAgainstRegex = (field, value) => {
    let valid
    let regex
    switch (field) {
      case "name":
        regex = /^[\u0600-\u065F\u066A-\u06EF\u06FA-\u06FFa-zA-Z]+\s*[\u0600-\u065F\u066A-\u06EF\u06FA-\u06FFa-zA-Z-_]*$/
        valid = regex.test(value)
        break
      case "phone":
        regex = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/
        valid = regex.test(value)
        break
      case "email":
        regex = /^([a-zA-Z0-9_\-.]+)@([a-zA-Z0-9_\-.]+)\.([a-zA-Z]{2,5})$/
        valid = regex.test(value)
        break
      case "message":
        regex = /^(?:[a-zA-Z0-9\s@,=%$#&_\u0600-\u06FF\u0750-\u077F\u08A0-\u08FF\uFB50-\uFDCF\uFDF0-\uFDFF\uFE70-\uFEFF]|(?:\uD802[\uDE60-\uDE9F]|\uD83B[\uDE00-\uDEFF])){0,30}$/
        valid = regex.test(value)
        break

      default:
        break
    }
    return valid
  }

  // Tracking user inputs
  const onChange = event => {
    const field = event.target.id
    const value = event.target.value
    switch (field) {
      case "name":
        setIsValidName(testAgainstRegex(field, value))
        setName(event.target.value)
        break
      case "phone":
        setIsValidPhone(testAgainstRegex(field, value))
        setPhone(event.target.value)
        break
      case "email":
        setIsValidEmail(testAgainstRegex(field, value))
        setEmail(event.target.value)
        break
      case "message":
        setIsValidMessage(testAgainstRegex(field, value))
        setMessage(event.target.value)
        break

      default:
        break
    }
  }

  const validate = () => {
    if (isValidName || isValidPhone || isValidEmail || isValidMessage) {
      setIsDialogOpen(true)
    }
  }

  const contactDetails = (
    <Grid
      container
      direction="column"
      justify="space-between"
      className={classes.details}
    >
      <Grid item>
        <Typography variant="h2" className={classes.detailsTitle}>
          شركة التميز للاستشارات
          <br />
          وأمن المعلومات
        </Typography>
      </Grid>
      <Grid
        item
        container
        direction="column"
        justify="space-evenly"
        spacing={2}
      >
        <Grid item container direction="column" justify="center">
          <Grid container direction="row-reverse" spacing={1} justify="center">
            <Grid item style={{ direction: "ltr" }}>
              <Typography
                component="a"
                href="TEL:+218917373202"
                className={`${classes.detailsInfo} ${classes.detailsInfoClickable}`}
              >
                +218 91-737-3202
              </Typography>
            </Grid>
            <Grid item style={{ color: "#fff" }}>
              <Icon color="inherit">
                <PhoneAndroid fontSize="large" />
              </Icon>
            </Grid>
          </Grid>
          <Grid container direction="row-reverse" spacing={1} justify="center">
            <Grid item style={{ direction: "ltr" }}>
              <Typography
                component="a"
                href="TEL:+218927373201"
                className={`${classes.detailsInfo} ${classes.detailsInfoClickable}`}
              >
                +218 92-737-3201
              </Typography>
            </Grid>
            <Grid item style={{ color: "#fff" }}>
              <Icon color="inherit">
                <PhoneAndroid fontSize="large" />
              </Icon>
            </Grid>
          </Grid>
          <Grid container direction="row-reverse" spacing={1} justify="center">
            <Grid item style={{ direction: "ltr" }}>
              <Typography
                component="a"
                href="TEL:+218214629298"
                className={`${classes.detailsInfo} ${classes.detailsInfoClickable}`}
              >
                +218 21-462-9298
              </Typography>
            </Grid>
            <Grid item style={{ color: "#fff" }}>
              <Icon color="inherit">
                <CallEnd fontSize="large" />
              </Icon>
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          <Grid container direction="row-reverse" spacing={1} justify="center">
            <Grid item style={{ direction: "ltr" }}>
              <Typography
                component="a"
                href="MAILTO:info@altamiuz.ly"
                className={`${classes.detailsInfo} ${classes.detailsInfoClickable}`}
              >
                Info@Altamiuz.ly
              </Typography>
            </Grid>
            <Grid item style={{ color: "#fff" }}>
              <Icon color="inherit">
                <Mail fontSize="large" />
              </Icon>
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          <Icon color="inherit">
            <LocationOn fontSize="large" />
          </Icon>
          <Typography className={classes.detailsInfo}>
            طرابلس - ليبيا
            <br />
            السبعة -مقابل مادي مول
            <br />
            العمارة 10 الدور الاول
          </Typography>
        </Grid>
        <Grid item>
          <Button
            variant="outlined"
            color="inherit"
            component="a"
            target="_blank"
            href="https://goo.gl/maps/oup2cQqhg8bqHmDq9"
            rel="noreferrer"
            className={`${classes.detailsInfo} ${classes.detailsInfoClickable}`}
            style={{ marginLeft: ".5rem", padding: "1rem" }}
          >
            عرض الموقع
            <Icon style={{ height: "100%" }}>
              <LocationOn />
            </Icon>
          </Button>
        </Grid>
      </Grid>
    </Grid>
  )
  return (
    <Layout pageMeta={pageMeta}>
      <Grid container direction="column" justify="center" alignItems="center">
        <Grid item className={classes.sectionSeperator} />
        <Grid item>
          <Typography variant="h1" className={classes.pageTitle}>
            شركة التميز للاستشارات
            <br />
            وأمن المعلومات
            <br />
            <a href="#form" className={classes.redText}>
              للتواصل معنا
              <br />
              <icon>
                <ArrowDownward fontSize="large" />
              </icon>
            </a>
          </Typography>
        </Grid>
        <Grid item className={classes.sectionSeperator} />
        <Grid item container className={classes.mapContainer}>
          <iframe
            title="Altamiuz location"
            src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d1675.9356242007352!2d13.259684989579032!3d32.8486604997622!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x0!2zMzLCsDUwJzU2LjIiTiAxM8KwMTUnMzQuOSJF!5e0!3m2!1sen!2sly!4v1618172242648!5m2!1sen!2sly"
            allowFullScreen
            loading="lazy"
            className={classes.mapIframe}
          />
        </Grid>
        <Card item container className={classes.contactUsContent}>
          <Grid item sm>
            <ContactForm
              classes={classes}
              onChange={onChange}
              name={name}
              phone={phone}
              email={email}
              message={message}
              isDialogOpen={isDialogOpen}
              setIsDialogOpen={setIsDialogOpen}
              isValidName={isValidName}
              isValidEmail={isValidEmail}
              isValidPhone={isValidPhone}
              isValidMessage={isValidMessage}
            />
            <DecoyForm
              classes={classes}
              onChange={onChange}
              name={name}
              phone={phone}
              email={email}
              message={message}
              isDialogOpen={isDialogOpen}
              setIsDialogOpen={setIsDialogOpen}
              isValidName={isValidName}
              isValidEmail={isValidEmail}
              isValidPhone={isValidPhone}
              isValidMessage={isValidMessage}
              validate={validate}
            />
          </Grid>
          <Grid item sm>
            {contactDetails}
          </Grid>
        </Card>
        <Grid item className={classes.sectionSeperator} />
      </Grid>
    </Layout>
  )
}

export default Contact
